import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProductsToState } from './../../../redux';

import SelectorParent from "../../../components/SelectorParent";
import NameAndText from "../../../components/NameAndText";
import MultiInputImage from "../../../components/MultiInputImage";
import ListDataProducts from "../../../components/ListDataProducts";
import References from "../../../components/References";
import DownloadPlans from "../../../components/DownloadPlans";
import ProdAssociated from "../../../components/ProdAssociated";

import "./style.css"

function CreateProduct() {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const products = useSelector(state => state.data.products);
    const categories = useSelector(state => state.data.categories);

    const [reload, setReload] = useState(0);
    const [formData, setFormData] = useState({
        parentId: '',

        name_fr: '',
        name_en: '',
        name_sp: '',
        name_de: '',

        text_fr: '',
        text_en: '',
        text_sp: '',
        text_de: '',

        benefits_fr: [],
        benefits_en: [],
        benefits_sp: [],
        benefits_de: [],

        features_fr: [],
        features_en: [],
        features_sp: [],
        features_de: [],

        ref_descrip_fr: '',
        ref_descrip_en: '',
        ref_descrip_sp: '',
        ref_descrip_de: '',
        table_ref: [],

        associated: [],
        images: Array(9).fill(null),
        files: []
    });

    const postFiles = async (type, file) => {
        const body = new FormData();

        if (type === 'img') {
            body.append('image', file);
        }
        else {
            body.append('plan', file);
        }

        const req = await fetch(`https://api.pro-vide.eu/admin/${type}`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${user.token}` },
            body: body
        });
        const res = await req.json();

        if (res) {
            return res
        }
        return ''
    }

    const postPlans = async () => {
        let plans = [];

        for (let plan of formData.files) {
            if (plan && plan !== null && plan !== '') {
                const planId = await postFiles('plan', plan);

                if (planId && planId !== '') {
                    plans.push(planId);
                }
            }
        }

        return plans
    }

    const postImages = async () => {
        let images = [];

        for (let img of formData.images) {
            if (img && img !== null) {
                const imageId = await postFiles('img', img);

                if (imageId && imageId !== '') {
                    images.push(imageId);
                }
            }
        }

        return images
    }

    const generateTableRef = async () => {
        if (formData.table_ref.length === 0 || formData.table_ref[0][2] === '') {
            return false
        }

        let table_ref = [formData.table_ref[0]];

        for (let i = 1; i < formData.table_ref.length; i++) {
            if (formData.table_ref[i][0] === '') {
                continue
            }

            if (formData.table_ref[i][1] === '') {
                table_ref.push(formData.table_ref[i]);
                continue
            }

            let row = [...formData.table_ref[i]];
            const schemaId = await postFiles('plan', formData.table_ref[i][1]);

            if (schemaId) {
                row[1] = schemaId;
            }

            table_ref.push(row);
        }
        return table_ref;
    }

    const addNewProduct = async () => {
        if (formData.name_fr === '' || formData.parentId === '') {
            return
        }

        const dataCheck = checkData();

        const table_ref = await generateTableRef();

        const plans = await postPlans();

        const images = await postImages();


        const body = {
            parentId: formData.parentId,
            name_fr: formData.name_fr,
            images: images,
            files: plans,
            online: false,
            ...dataCheck
        };

        if (table_ref) {
            body.table_ref = table_ref
        }

        const req = await fetch(`https://api.pro-vide.eu/admin/products`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(body)
        });
        const res = await req.json();

        if (res && res._id) {
            dispatch(addProductsToState([...products, res]));
            setReload(p => p + 1);
            setFormData({
                parentId: '',

                name_fr: '',
                name_en: '',
                name_sp: '',
                name_de: '',

                text_fr: '',
                text_en: '',
                text_sp: '',
                text_de: '',

                benefits_fr: [],
                benefits_en: [],
                benefits_sp: [],
                benefits_de: [],

                features_fr: [],
                features_en: [],
                features_sp: [],
                features_de: [],

                ref_descrip_fr: '',
                ref_descrip_en: '',
                ref_descrip_sp: '',
                ref_descrip_de: '',
                table_ref: [],

                associated: [],
                images: Array(9).fill(null),
                files: []
            })
            return
        }
        alert('Erreur lors de l\'ajout du produit');
    }

    function checkData() {
        let body = {};

        const fields = [
            'name_en', 'name_sp', 'name_de', 'text_fr', 'text_en', 'text_sp', 'text_de', 'ref_descrip_fr', 'ref_descrip_en', 'ref_descrip_sp', 'ref_descrip_de'
        ];

        for (let field of fields) {
            if (formData[field] && formData[field] !== '') {
                body[field] = formData[field];
            }
        }

        const arrays = [
            'benefits_fr', 'benefits_en', 'benefits_sp', 'benefits_de', 'features_fr', 'features_en', 'features_sp', 'features_de', 'associated'
        ];

        for (let array of arrays) {
            if (formData[array] && formData[array].length !== 0) {
                let newArray = [];

                for (let data of formData[array]) {
                    if (data && data !== null && data !== '') {
                        newArray.push(data);
                    }
                }

                body[array] = newArray;
            }
        }

        return body;
    }

    return (
        <div className='form_container form_createProduct'>
            <i className="slash"></i>

            {categories && categories.length !== 0 &&
                <SelectorParent data={categories} setParentId={setFormData} type='prod' />
            }

            <i className="slash"></i>

            <h2>Ajouter un produit</h2>

            {formData && formData.parentId && formData.parentId !== '' &&
                <>
                    <section className="sect_nameAndText_multiLang">
                        <NameAndText lang='fr' holder='du produit' nameValue={formData.name_fr} textValue={formData.text_fr} setFormData={setFormData} />
                        <NameAndText lang='en' holder='du produit' nameValue={formData.name_en} textValue={formData.text_en} setFormData={setFormData} />
                        <NameAndText lang='sp' holder='du produit' nameValue={formData.name_sp} textValue={formData.text_sp} setFormData={setFormData} />
                        <NameAndText lang='de' holder='du produit' nameValue={formData.name_de} textValue={formData.text_de} setFormData={setFormData} />
                    </section>

                    {formData.images.length !== 0 &&
                        <MultiInputImage dataImages={formData.images} reloading={reload} setFormData={setFormData} />
                    }

                    <i className="slash"></i>

                    <ListDataProducts formData={formData} setFormData={setFormData} />

                    <i className="slash"></i>

                    <References formData={formData} setFormData={setFormData} />

                    <i className="slash"></i>

                    <DownloadPlans formData={formData} setFormData={setFormData} />

                    <i className="slash"></i>

                    <ProdAssociated formData={formData} setFormData={setFormData} />

                    <button onClick={() => addNewProduct()} className="submitForm blue">Ajouter le produit</button>
                </>
            }
        </div>
    )
}

export default CreateProduct;