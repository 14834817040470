import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addRealizationToState } from "../../../redux";

import SelectorRealization from "../../../components/SelectorRealization";
import NameAndText from "../../../components/NameAndText";
import MultiInputImage from "../../../components/MultiInputImage";

import "./style.css"

function UpdateRealization() {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const categories = useSelector(state => state.data.categories);
    const realizations = useSelector(state => state.data.realization);

    const [realizationSelected, setRealizationSelected] = useState(null);
    const [reload, setReload] = useState(0);

    const [formData, setFormData] = useState({
        parentId: '',

        name_fr: '',
        name_en: '',
        name_sp: '',
        name_de: '',

        text_fr: '',
        text_en: '',
        text_sp: '',
        text_de: '',

        images: Array(9).fill(null)
    });

    // Recup des données de la catégorie selectionné
    useEffect(() => {
        if (!realizationSelected) {
            setFormData({
                parentId: '',

                name_fr: '',
                name_en: '',
                name_sp: '',
                name_de: '',

                text_fr: '',
                text_en: '',
                text_sp: '',
                text_de: '',

                images: Array(9).fill(null)
            });
            return
        }

        const fetchLastData = async () => {
            setFormData({ ...realizationSelected, images: Array(9).fill(null) });
        }

        fetchLastData();
    }, [realizationSelected]);

    const updateImages = async (i) => {
        const newImage = formData.images[i];
        const lastImage = realizationSelected.images[i];

        // On ajoute rien
        if (newImage === null && !lastImage) {
            return false
        }

        // On ajoute l'ancien image_id
        if (newImage !== null && !newImage.name && lastImage) {
            return lastImage
        }

        // Add / Update
        if (newImage && newImage.name) {
            if (lastImage) {
                const req = await fetch(`https://api.pro-vide.eu/admin/img/${lastImage}`, {
                    method: 'DELETE',
                    headers: { 'Authorization': `Bearer ${user.token}` }
                });
                const res = await req.json();

                if (res !== true) {
                    alert(`Impossible de supprimer l'ancienne image n°${i + 1}`);
                    return 'error'
                }
            }

            const body = new FormData();
            body.append('image', newImage);

            const req = await fetch(`https://api.pro-vide.eu/admin/img`, {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${user.token}` },
                body: body
            });
            const res = await req.json();

            if (res) {
                return res;
            }
            alert(`Impossible d'ajouter l'image n°${i + 1}`);
            return 'error'
        }

        // Delete
        if (newImage === null && lastImage) {
            const req = await fetch(`https://api.pro-vide.eu/admin/img/${lastImage}`, {
                method: 'DELETE',
                headers: { 'Authorization': `Bearer ${user.token}` }
            });
            const res = await req.json();

            if (res !== true) {
                alert(`Impossible de supprimer l'ancienne image n°${i + 1}`);
                return 'error'
            }
        }
    }

    const updateRealization = async () => {
        let bodyReq = {
            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,
            online: false
        }

        let images = [];
        let stop = false;

        for (let i = 0; i < formData.images.length; i++) {
            if (stop) {
                return
            }

            const image = await updateImages(i);

            if (image === 'error') {
                return
            }
            if (image) {
                images.push(image);
            }
        }

        bodyReq.images = images;

        const req = await fetch(`https://api.pro-vide.eu/admin/realization/${realizationSelected._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(bodyReq)
        });
        const res = await req.json();

        if (res && res.length !== 0) {
            dispatch(addRealizationToState(res));
            setReload(p => p + 1);
            return
        }
        alert('Les fichiers et images on bien était traité mais le service n\'a pas était mis à jour. CONTACTEZ LE SUPPORT SANS ATTENDRE')
    }

    return (
        <div className='form_container form_updateRealization'>
            <i className="slash"></i>

            {categories && categories.length !== 0 &&
                <SelectorRealization allCategories={categories} allRealizations={realizations} setRealizationSelected={setRealizationSelected} />
            }

            <i className="slash"></i>

            <h2>Modifier une réalisation</h2>

            {realizationSelected && formData &&
                <section className="sect_nameAndText_multiLang">
                    <NameAndText lang='fr' holder='de la réalisation' nameValue={formData.name_fr} textValue={formData.text_fr} setFormData={setFormData} />
                    <NameAndText lang='en' holder='de la réalisation' nameValue={formData.name_en} textValue={formData.text_en} setFormData={setFormData} />
                    <NameAndText lang='sp' holder='de la réalisation' nameValue={formData.name_sp} textValue={formData.text_sp} setFormData={setFormData} />
                    <NameAndText lang='de' holder='de la réalisation' nameValue={formData.name_de} textValue={formData.text_de} setFormData={setFormData} />
                </section>
            }

            {realizationSelected && formData && formData.images.length !== 0 &&
                <MultiInputImage lastImages={realizationSelected?.images} dataImages={formData.images} reloading={reload} setFormData={setFormData} />
            }

            {realizationSelected &&
                <button onClick={() => updateRealization()} className="submitForm black">Modifier la réalisation</button>
            }
        </div>
    )
}

export default UpdateRealization;