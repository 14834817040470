import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addServicesToState } from "../../../redux";

import SelectorParent from "../../../components/SelectorParent";
import NameAndText from "../../../components/NameAndText";
import MultiInputImage from "../../../components/MultiInputImage";
import Input from "../../../components/Input";

import icon_delete from './../../../assets/svg/btn_close.svg';

import "./style.css"

function CreateService() {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const services = useSelector(state => state.data.services);
    const categories = useSelector(state => state.data.categories);

    const [reload, setReload] = useState(0);
    const [formData, setFormData] = useState({
        parentId: '',

        name_fr: '',
        name_en: '',
        name_sp: '',
        name_de: '',

        text_fr: '',
        text_en: '',
        text_sp: '',
        text_de: '',

        price: '',
        flyer: '',
        video: '',
        images: Array(9).fill(null)
    });

    const handleChangeFile = (e) => {
        const file = e.target.files[0];

        setFormData({
            ...formData,
            flyer: file ? file : ''
        });
    }

    const postImages = async () => {
        let array = [];

        for (let img of formData.images) {
            if (img === null) {
                continue
            }

            const body = new FormData();
            body.append('image', img);

            const req = await fetch(`https://api.pro-vide.eu/admin/img`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${user.token}` // Correction de l'en-tête Authorization
                },
                body: body
            });
            const res = await req.json();
            if (res) {
                array.push(res);
            }
        }
        return array
    }

    const postFile = async () => {
        if (formData.flyer === '') {
            return null
        }

        const body = new FormData();
        body.append('plan', formData.flyer);

        const req = await fetch(`https://api.pro-vide.eu/admin/plan`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${user.token}` },
            body: body
        });
        const res = await req.json();
        if (res) {
            return res;
        }
        return null
    }

    const addNewService = async () => {
        if (formData.name_fr === '' || formData.parentId === '') {
            return
        }

        let bodyReq = {
            parentId: formData.parentId,

            name_fr: formData.name_fr,
            name_en: formData.name_en,
            name_sp: formData.name_sp,
            name_de: formData.name_de,

            text_fr: formData.text_fr,
            text_en: formData.text_en,
            text_sp: formData.text_sp,
            text_de: formData.text_de,

            price: formData.price,
            video: formData.video,
            online: false
        }

        const arrayImages = await postImages();
        bodyReq.images = arrayImages;

        const filePosted = await postFile();

        if (filePosted !== null) {
            bodyReq.flyer = filePosted;
        }

        const req = await fetch(`https://api.pro-vide.eu/admin/services`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify(bodyReq)
        });
        const res = await req.json();

        if (res && res._id) {
            dispatch(addServicesToState([...services, res]));
            setFormData({
                parentId: formData.parentId,

                name_fr: '',
                name_en: '',
                name_sp: '',
                name_de: '',

                text_fr: '',
                text_en: '',
                text_sp: '',
                text_de: '',

                price: '',
                flyer: '',
                video: '',
                
                images: Array(9).fill(null)
            })
            setReload(p => p + 1);
        }
    }

    return (
        <div className='form_container form_createService'>
            <i className="slash"></i>

            {categories && categories.length !== 0 &&
                <SelectorParent data={categories} setParentId={setFormData} type='serv' />
            }

            <i className="slash"></i>

            <h2>Ajouter un service</h2>

            {formData && formData.parentId !== '' && formData.parentId !== null &&
                <section className="sect_nameAndText_multiLang">
                    <NameAndText lang='fr' holder='de la catégorie' nameValue={formData.name_fr} textValue={formData.text_fr} setFormData={setFormData} />
                    <NameAndText lang='en' holder='de la catégorie' nameValue={formData.name_en} textValue={formData.text_en} setFormData={setFormData} />
                    <NameAndText lang='sp' holder='de la catégorie' nameValue={formData.name_sp} textValue={formData.text_sp} setFormData={setFormData} />
                    <NameAndText lang='de' holder='de la catégorie' nameValue={formData.name_de} textValue={formData.text_de} setFormData={setFormData} />
                </section>
            }

            {formData && formData.parentId !== '' && formData.parentId !== null &&
                <div className="container_fields">
                    <Input label='Prix' placeholder='199.99' value={formData.price} setValue={setFormData} field='price' />
                    <Input label='Video' placeholder='http://link-video.fr' value={formData.video} setValue={setFormData} field='video' />

                    <div className='input_file'>
                        <label>Flyer</label>

                        <div className="input">
                            <p className="filename">{formData && formData.flyer !== '' ? formData.flyer.name : '+ Ajouter un pdf'}</p>

                            <input type="file" onChange={(e) => handleChangeFile(e)} />

                            {formData && formData.flyer !== '' &&
                                <img
                                    src={icon_delete}
                                    alt="_"

                                    className="btn_delete"
                                    onClick={() => setFormData({ ...formData, flyer: '' })}
                                />
                            }
                        </div>
                    </div>
                </div>
            }

            {formData && formData.parentId !== '' && formData.parentId !== null && formData.images.length !== 0 &&
                // eslint-disable-next-line react/jsx-pascal-case
                <MultiInputImage dataImages={formData.images} reloading={reload} setFormData={setFormData} />
            }

            {formData && formData.parentId !== '' && formData.parentId !== null &&
                <button onClick={() => addNewService()} className="submitForm blue">Ajouter le service</button>
            }
        </div>
    )
}

export default CreateService;